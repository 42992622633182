import React, {useEffect, useState} from "react";
// import fs from ""
// import {writeFile}  from "fs"
import {analyticsBACK} from "../../ApiEndpoints";
import Skeleton from "react-loading-skeleton";

const GetPossibleTypes = () => {
	const [state, setState] = useState("fetching")

	useEffect(() => {
		analyticsBACK.post('/graphQL', {
			query: `query Introspection{
				  __schema {
				    types {
				      kind
				      name
				      possibleTypes {
				        name
				      }
				    }
				  }
				}`,
			variables: {},
			operationName: 'Introspection'
		})
			.then(result => {
				const possibleTypes = {};

				result.data.data.__schema.types.forEach(supertype => {
					if (supertype.possibleTypes) {
						possibleTypes[supertype.name] =
							supertype.possibleTypes.map(subtype => subtype.name);
					}
				});

				setState('fetched')
				console.log(possibleTypes)
				// writeFile('./possibleTypes.json', JSON.stringify(possibleTypes))
				// 	.then((resp) => {
				// 		console.log("RESP", resp)
				// 		setState('done')
				// 	})
				// 	.catch(resp => {
				// 		console.log("ERROR", resp)
				// 		setState('save fail')
				// 	})
			})
			.catch(reult => {
				setState('fail')
			})
	}, [])

	switch (state){
		case "fetching" : {
			return <Skeleton width="300px" />
		}
		case "fetched" : {
			return <h1>Data fetched, but not saved</h1>
		}
		case "fail": {
			return <h1>Fail to get response</h1>
		}
		case "save fail": {
			return <h1>Fail to save response</h1>
		}

	}
	return <div>All done</div>
}
export default GetPossibleTypes
