import React, {Suspense} from "react";
import {Route, Routes} from "react-router-dom";
import LoaderPage from "./components/laders/LoaderPage";
import Main from "./routes/Main/Main";
import AuthPage from "./routes/Auth/AuthPage";
import Query from "./routes/Query/Query";
import MainTheme from "./components/theme/MainTheme";
import Dossier from "./routes/Concepts/Dossier";
import GetPossibleTypes from "./routes/API/GetPossibleTypes";
import PageStream from "./routes/Stream/PageStream";
import PageStreamDetail from "./routes/Stream/PageStreamDetail";
import PageAllStreams from "./routes/Stream/PageAllStreams";
import Remembered from "./routes/Concepts/Remembered";
import PageReports from "./routes/Reports/PageReports";
import Connections from "./routes/Concepts/Connections";
import ConnectionsDetail from "./routes/Concepts/ConnectionsDetail";
import History from "./routes/History/History";
import Invite from "./routes/Invite/Invite";

const App = () => {

	return <>
		<Routes>
			<Route path="/" element={<Suspense fallback={<LoaderPage />}><MainTheme><Main /></MainTheme></Suspense>} />
			<Route path="/auth" element={<Suspense fallback={<LoaderPage />}> <AuthPage /> </Suspense>} />

			<Route path="/concept" element={<Suspense fallback={<LoaderPage />}><MainTheme><Query /></MainTheme></Suspense> } />
			<Route path="/concept/:conceptId/dossier" element={<Suspense fallback={<LoaderPage />}><MainTheme><Dossier /></MainTheme></Suspense>} />
			<Route path="/concept/remembered" element={<Suspense fallback={<LoaderPage />}><MainTheme><Remembered /></MainTheme></Suspense>} />

			<Route path="/stream" element={<Suspense fallback={<LoaderPage />}><MainTheme><PageStream /></MainTheme></Suspense> } />
			<Route path="/stream/content" element={<Suspense fallback={<LoaderPage />}><MainTheme><PageStreamDetail /></MainTheme></Suspense> } />
			<Route path="/stream/all" element={<Suspense fallback={<LoaderPage />}><MainTheme><PageAllStreams /></MainTheme></Suspense> } />

			<Route path="/history" element={<Suspense fallback={<LoaderPage />}><MainTheme><History /></MainTheme></Suspense> } />

			<Route path="/reports" element={<Suspense fallback={<LoaderPage />}><MainTheme><PageReports /></MainTheme></Suspense> } />

			<Route path="/connections" element={<Suspense fallback={<LoaderPage />}><MainTheme><Connections /></MainTheme></Suspense> } />
			<Route path="/connections/:conceptsID" element={<Suspense fallback={<LoaderPage />}><MainTheme><ConnectionsDetail /></MainTheme></Suspense> } />
			<Route path="/connections/:conceptsID/:connectionsType" element={<Suspense fallback={<LoaderPage />}><MainTheme><ConnectionsDetail /></MainTheme></Suspense> } />

			<Route path="/invite/:secret" element={<Suspense fallback={<LoaderPage />}><Invite /></Suspense> }/>

			<Route path="/gettypes" element={<GetPossibleTypes />} />
		</Routes>
	</>
}

export default App