const loadImage = async url => {
	const img = document.createElement('img')
	img.src = url
	return new Promise((resolve, reject) => {
		img.onload = () => resolve(img)
		img.onerror = reject
	})
}

const convertSVGtoImg = async ({svg, format = 'png'}) => {
	const svgAsXML = (new XMLSerializer()).serializeToString(svg)
	const svgData = `data:image/svg+xml,${encodeURIComponent(svgAsXML)}`

	const img = await loadImage(svgData)

	const canvas = document.createElement('canvas')
	canvas.width = svg.clientWidth
	canvas.height = svg.clientHeight
	canvas.getContext('2d').drawImage(img, 0, 0, svg.clientWidth, svg.clientHeight)

	const dataURL = canvas.toDataURL(`image/${format}`, 1.0)
	return dataURL;
}
export default convertSVGtoImg