export const clearTime = (date) => {
	date.setHours(0)
	date.setMinutes(0)
	date.setSeconds(0)
	date.setMilliseconds(0)
	return date
}
export const getDay = (date, n) => {
	date = clearTime(date)
	date.setDate(date.getDate() + n)
	return date.getTime()
}

export const getMonthShortName = (date) => {
	return date.toLocaleString('ru', {month: 'short'})
}
export const getMonthShortNameFromUTC = (time) => {
	let date = new Date(time)
	return getMonthShortName(date)
}
export const getMonthName = (date) => {
	return date.toLocaleString('ru', {month: 'long'})
}
export const getWeekDayShort = (date) => {
	return date.toLocaleString('ru', {weekday: 'short'})
}
export const getWeekDayShortFromUTC = (time) => {
	return getWeekDayShort(new Date(time))
}
export const getShortDate = (date) => {
	return date.toLocaleString('ru', {day: '2-digit', month: "2-digit"})
}
export const getShortDateFromUTC = (time) => {
	return getShortDate(new Date(time))
}
export const getFirstDayOfWeek = (d) => {
	// 👇️ clone date object, so we don't mutate it
	const date = new Date(d)
	const day = date.getDay() // 👉️ get day of week

	// 👇️ day of month - day of week (-6 if Sunday), otherwise +1
	const diff = date.getDate() - day + (day === 0 ? -6 : 1)

	return new Date(date.setDate(diff))
}

export const getLastDayOfWeek = (d) => {
	const firstDay = getFirstDayOfWeek(d)
	const lastDay = new Date(firstDay)
	return lastDay.setDate(lastDay.getDate() + 6)
}

export const getFirstDayOfMonth = (date) => {
	return new Date(date.getFullYear(), date.getMonth(), 1)
}
export const getLastDayOfMonth = (date) => {
	return new Date(date.getFullYear(), date.getMonth() + 1, 0)
}