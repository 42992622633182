import React, {useState, useEffect} from 'react';
import {HandySvg} from "handy-svg";
import {analyticsBACK, APIClient} from "../../ApiEndpoints";
import {
	CONCEPTS,
	STORIES_BY_CONCEPTS_ALL_PLATFORMS,
	STORIES_BY_CONCEPTS_AND_PLATFORMS,
	STORIES_BY_CONCEPTS_ALL_PLATFORMS_DATES,
	STORIES_BY_CONCEPTS_AND_PLATFORMS_DATES, STORIES_BY_QUERY_AND_PLATFORMS
} from "../../graphql/streamQueries";
import ObjectsSelect from "../../components/concepts/stream/ObjectsSelect";
import StreamDetailSource from "../../components/stream/StreamDetailSource";
import Modal from "../../components/ui/Modal";
import Skeleton from "react-loading-skeleton";
import ico_doc from "../../ico/doc.svg";
import {BACK_HOST} from "../../Consts";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useSearchParams} from "react-router-dom";
import {setStreamList} from "../../reducers/streamsSlice";
import {setHistory} from "../../reducers/userSlice";
import {isArray} from "@apollo/client/cache/inmemory/helpers";

const QueryDetailLine = React.lazy(() => import("../../components/ui/reports/QueryDetailLine"))
const ConceptDocs = React.lazy(() => import("../../components/concepts/dossier/ConceptDocs"))

function PageStreamDetail() {
	const [modalRename, setModalRename] = useState(false)
	const user = useSelector(state => state.user.value)
	const dispatch = useDispatch()

	const [search, setSearch] = useSearchParams();

	const streams = useSelector(state => state.stream.list)

	const [conceptsArray, setConceptsArray] = useState((search.get('concepts'))? search.get('concepts').split(',') : [])
	const [sourcesArray, setSourcesArray] = useState((search.get('sources'))? search.get('sources').split(',') : [])
	const [searchQuery, setSearchQuery] = useState((search.get('q'))? JSON.parse(search.get('q')) : false)
	const [qString, setQString] = useState(false)

	const [fetching, setFetching] = useState({
		concepts: true,
	})
	const [streamName, setStreamName] = useState ('Название еще не введено')
	const [concepts, setConcepts] = useState([])
	const [checkboxes, setCheckboxes] = useState({
		streamMeta: true,
		streamComment: true
	});
	const [docsPage, setDocsPage] = useState(false)
	const [docsTotal, setDocsTotal] = useState(false)
	const [streamComment, setStreamComment] = useState()
	const [materialCheckboxes, setMaterialCheckboxes] = useState({})
	const [modalOpened, setModalOpened] = useState(false);
	const [fileCreating, setFileCreating] = useState(false)
	const [reportDownloadLink, setReportDownloadLink] = useState(false)
	const query = useLocation().search

	useEffect(() => {
		document.title = 'Мгимо аналитика: лента ' + ((streamName === 'Название еще не введено')? '' : streamName)
	}, [streamName])

	useEffect(() => {
		if(search.get('from_saved')){
			analyticsBACK.post('/stream/set_shown', {
				userID: user.id,
				streamID: search.get('from_saved')
			})
				.then(resp => {
					if(isArray(resp.data))
						dispatch(setStreamList(resp.data))
					let updatesCount = 0
					resp.data.forEach(stream => {
						if(stream.updated){
							updatesCount += 1
						}
					})
				})
		}
	}, [])

	useEffect(() => {
		if(searchQuery.length > 0){
			let tmp = ''
			let concepts = []
			searchQuery.forEach(phrase => {
				switch (phrase.type){
					case 'bool': {
						switch (phrase.text){
							case 'AND': {
								tmp += ' '
								break
							}
							case 'NOT': {
								tmp += '-'
								break
							}
							default:{//OR
								tmp += ','
							}
						}
						break
					}
					case 'concept':{
						concepts.push(`"${phrase.id}"`)
						break
					}
					default: { //text
						tmp += `\"${phrase.text}\"`
					}
				}
			})
			if(concepts.length > 0)
				tmp += 'Концепт(.идентификатор=(' + concepts.join(',') + '))'
			setQString(tmp)
		}
	},[searchQuery])

	useEffect(() => {
		analyticsBACK.post('/history', {
			userID: user.id,
			link: '/stream/content' + query,
			title: 'Лента ' + ((streamName === 'Название еще не введено')? searchQuery.map(query => {return query.text}).join(' ') : streamName)
		})
			.then(resp => {
				if(resp.data)
					dispatch(setHistory(resp.data))
			})
			.catch(resp => {
				console.error('Ошибка добавления в историю', resp)
			})

	}, [])

	useEffect(() => {
		if(streams && search.get('from_saved')){
			const found = streams.filter(f => f.id === parseInt(search.get('from_saved')))
			if(found && typeof found[0] === "object"){
				setStreamName(found[0].name)
				setStreamComment(found[0].comment)
			}
		}
	},[streams])

	useEffect( () => {
		if(docsPage.showTotal)
			setDocsTotal(docsPage.showTotal)
	}, [docsPage])

	const renameStream = (e) => {
		e.preventDefault()
		setStreamName(e.target.querySelector('textarea[name="stream-name"]').value)
		setModalRename(false)
	}

	const makeReport = async () => {
		setFileCreating(true)
		setModalOpened(false)
		let sections = []

		//stream meta
		const isStreamMeta = (document.querySelector('input[name="streamMeta"]'))? document.querySelector('input[name="streamMeta"]').checked : false
		if(isStreamMeta){
			let metaHtml = document.querySelector('#streamMeta').innerHTML
			sections.push({type: 'html', content: metaHtml})
		}

		//comment
		const isStreamComment = (document.querySelector('input[name="streamComment"]'))? document.querySelector('input[name="streamComment"]').checked : false
		if(isStreamComment){
			if(streamComment){
				sections.push({type: 'text', content: streamComment, title: 'Комментарий'})
			}
		}

		//docs
		const isConceptDocs = (document.querySelector('input[name="ConceptDocs"]'))? document.querySelector('input[name="ConceptDocs"]').checked : false
		if(isConceptDocs) {
			let docsContent = document.createElement('DIV')
			let docsContentParent = document.querySelector('#ConceptDocs')
			let docsHTML = ''
			docsContent.innerHTML = docsContentParent.innerHTML
			docsContent.querySelectorAll('.tooltip, .tabs-head, .detail-material-itm-content__top a').forEach(obj => {
				obj.remove()
			})
			docsContent.querySelectorAll('.detail-material-itm-content__info__date').forEach(obj => {//parse date
				if(obj.innerText.length > 0)
					obj.innerHTML = obj.querySelector('span:first-child').innerText + ' ' + obj.querySelector('span:last-child').innerText
			})

			if (docsContentParent.querySelector('.tabs-head__itm.active').innerText === 'Только заголовок') {
				docsContent.querySelectorAll('.detail-material-itm-content__text').forEach(obj => {
					obj.remove()
				})
			} else if (docsContentParent.querySelector('.tabs-head__itm.active').innerText === 'Заголовок + анонс') {
				docsContent.querySelectorAll('.detail-material-itm-content__text').forEach(obj => {
					obj.querySelector('.detail-material-itm-content__text__inner').innerText = (obj.innerText.length > 300) ? obj.innerText.substring(0, 300) + '...' : obj.innerText;
				})
			}

			docsContent.querySelectorAll('.detail-material-itm').forEach(obj => {
				if (obj.querySelector('input[type="checkbox"]').checked) {
					docsHTML += obj.querySelector('.detail-material-itm-content').outerHTML
				}
			})
			sections.push({type: 'html', content: docsHTML, title: 'Материалы'})
		}

		//sending report data
		if(sections.length > 0) {
			let concepts = conceptsArray.join(',') + '-' + sourcesArray.join(',')
			analyticsBACK.post('/reports',
				{
					sections: sections,
					concept: concepts,
					conceptName: streamName,
					report_type: 'stream',
					user_id: user.id,
					link: '/stream/content' + query
				},
				// {responseType: 'blob'}
			)
				.then(resp => {
					if (resp.data.file) {
						setModalOpened(true)
						setReportDownloadLink(resp.data.file)
						// fileDownload(resp.data, `report${new Date().toLocaleDateString('ru')}.docx`);
					} else {
						console.error('bad request')
					}
					setFileCreating(false)
					setModalOpened(true)
				})
				.catch(() => {
					setFileCreating('fail')
				})
		}else {

		}
		return true
	}

	return (
		<main className="main-content">
			<div className="content visible">
				<div className="query-detail-page">

					<div className="title-big c-main-dark">Лента по параметрам:</div>

					{searchQuery.length > 0 &&
						<div className='stream-query'>
							<div className="title c-main-dark" style={{marginTop: '30px', marginBottom: "18px"}}>Поисковый запрос:</div>
							{searchQuery.map((phrase, index) => {
								if (phrase.type === 'bool'){
									return <div key={`${phrase.text}_${index}`} className={`search-phrases__item search-phrases__item--bool ${phrase.text}`}>{phrase.text}</div>
								}
								if(phrase.type === 'text')
									return <div key={`${phrase.text}_${index}`} className='search-phrases__item title search-phrases__item--text'>{phrase.text}</div>

								if(phrase.type === 'concept')
									return <div key={`${phrase.text}_${index}`} className='search-phrases__item title search-phrases__item--concept'>{phrase.text}</div>
							})}
						</div>
					}

					{qString && docsPage.listPlatformCountFacet &&
						<StreamDetailSource streamComment={streamComment} sourcesArray={sourcesArray} streamName={streamName} docsTotal={docsTotal} qString={qString} docsFacet={(docsPage.listPlatformCountFacet)? docsPage.listPlatformCountFacet : []} />
					}

					<div className="query-detail-section center">
						<div className="query-detail-section__content">
									<span className="stream-rename-btn c-main" onClick={(e) => {
										setModalRename(true)
									}}>
										Переименовать ленту
									</span>
							<Modal className="stream-rename-modal" opened={modalRename}
							       setOpened={setModalRename}>
								<form action="" onSubmit={e => renameStream(e)} className="nice-form">
									<div className="title ta-c c-main-dark stream-rename-modal__title">Введите
										название сохраняемой ленты
									</div>
									<textarea name="stream-name" rows="4" placeholder="Название ленты"></textarea>
									<button className="btn small stream-rename-modal__btn">Сохранить</button>
								</form>
							</Modal>
						</div>
						<div className="query-detail-section__aside">
							<div className="small light ta-c">
								Включать в&nbsp;отчет
							</div>
						</div>
					</div>

					<QueryDetailLine name="streamMeta" checkboxes={checkboxes} setCheckboxes={setCheckboxes}>
						<div id='streamMeta'>
							<h1 className="title-big c-main-dark">
								{streamName}
							</h1>

							<div className="stream-num-block">
								<div className="title-big c-main-dark">
									{docsTotal !== false
										? docsTotal
										: <Skeleton width="60px" />
									}
								</div>
								<div className="stream-num-block__text">
									Количество найденных материалов,<br/>
									удовлетворяющих условиям поиска
								</div>
							</div>
						</div>
					</QueryDetailLine>

					<QueryDetailLine name="streamComment" checkboxes={checkboxes} setCheckboxes={setCheckboxes}>
						<div className="nice-form">
							<div className="title c-main-dark mb-30">Комментарий к ленте</div>
							<textarea onChange={e => setStreamComment(e.target.value)} value={streamComment} name="" id="" rows="6" placeholder="Введите комментарий"></textarea>
						</div>
					</QueryDetailLine>

					{qString &&
						<ConceptDocs
							defVars = { (sourcesArray[0] === 'all')
								?{limit: 20, q: qString, platforms: null}
								:{limit: 20, q: qString, platforms: sourcesArray}
							}
							query = {STORIES_BY_QUERY_AND_PLATFORMS}
							datesQuery = {STORIES_BY_QUERY_AND_PLATFORMS}
							title = ''
							checkboxes={checkboxes}
							setCheckboxes={setCheckboxes}
							materialCheckboxes={materialCheckboxes}
							setMaterialCheckboxes={setMaterialCheckboxes}
							resp = {setDocsPage}
						/>

					}

					<div className="detail-export">
						<div aria-disabled={(fileCreating)} className="btn detail-export__btn" onClick={() =>{
							// if(!reportDownloadLink)
							makeReport()
							// else
						}}>
							<HandySvg src={ico_doc} width={28} height={34}></HandySvg>
							Экспорт отчета в формате .docx
						</div>
						{fileCreating === 'fail' &&
							<p className='ta-c' style={{fontSize: "1.5rem"}}>Ошибка при создании отчета</p>
						}
						<Modal className="modal--export" opened={modalOpened} setOpened={setModalOpened}>
							<div className="modal-export-top">
								{reportDownloadLink &&
									<a className="btn" href={`${BACK_HOST}/public/${reportDownloadLink}`}  download={true}>
										<HandySvg src={ico_doc} width={28} height={34}></HandySvg>
										Скачать файл отчета
									</a>
								}
							</div>
							{/*<div className="modal-export-bot">*/}
							{/*	<div className="title ta-c c-main-dark">Отправить на email</div>*/}
							{/*	<form className="modal-export-form nice-form">*/}
							{/*		<label className="input-wrap">*/}
							{/*			<input type="text" name="login" placeholder=" "/>*/}
							{/*			<span className="input-wrap__label">Введите email</span>*/}
							{/*		</label>*/}
							{/*		<button className="btn small">Отправить</button>*/}
							{/*	</form>*/}
							{/*</div>*/}
						</Modal>
					</div>

				</div>
			</div>
		</main>
	)
}

export default PageStreamDetail;