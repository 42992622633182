import React, {useEffect, useState} from 'react';
import {Link, useSearchParams} from "react-router-dom";
import ListBlock from "../../components/ui/listBlock/ListBlock";
import {userSlice} from "../../reducers/userSlice";
import {useSelector} from "react-redux";

const SourceListSelect = React.lazy(() => import("../../components/stream/SourceListSelect"))
const QueryStringConstructor = React.lazy(() => import("../../components/stream/QueryStringConstructor"))

const ShowBtn = ({phrases, sources, concepts, conceptLogic}) => {
	if ((sources.length > 0) && (concepts.length > 0 || phrases.length > 0)) {
		let URL = []
		let queryString = ''
		if(sources[0] === 'all'){
			URL.push('sources=all')
		} else {
			URL.push('sources=' + sources.map(source => {return source.id}).join(','))
		}
		if(phrases.length > 0){
			phrases.forEach(phrase => {
				if(phrase.type === 'text')
					queryString += `(${phrase.text})`
				if(phrase.type === 'bool'){
					switch (phrase.text) {
						case "AND": {
							queryString += ` `
							break
						}
						case "NOT": {
							queryString += `-`
							break
						}
						default: {//OR
							queryString += `,`
						}
					}
				}
			})
		}
		if(phrases.length > 0 && concepts.length > 0){
			switch (conceptLogic) {
				case "AND": {
					queryString += ` `
					break
				}
				case "NOT": {
					queryString += `-`
					break
				}
				default: {//OR
					queryString += `,`
				}
			}
		}
		if(concepts.length > 0){
			queryString += 'Концепт(.идентификатор=('
			concepts.forEach((concept, index) => {
				if(index === 0)
					queryString += `"${concept.id}"`
				else
					queryString += `,"${concept.id}"`
			})
			queryString += ')'
		}
		let qArray = [...phrases]
		if(phrases.length > 0 && concepts.length > 0)
			qArray = [...qArray, conceptLogic, ...concepts]
		else if(concepts.length > 0)
			qArray = [...concepts]

		console.log(qArray)
		URL.push('q='+JSON.stringify(qArray))
		return <Link to={`/stream/content?${URL.join('&')}`} className="btn stream-finish-btn">Сформировать ленту</Link>
	}

	return <></>;
}

function PageStream() {
	useEffect(() => {
		document.title = 'Мгимо аналитика: создание ленты'
	}, [])
	const [search, setSearch] = useSearchParams();
	const savedConcepts = useSelector(state => state.user.savedConcepts)
	const [conceptSelect, setConceptSelect] = useState([]);
	const [conceptsLogic, setConceptLogic] = useState({text: 'OR', type: 'bool'})
	const [countriesSelected, setCountriesSelected] = useState([]);
	const [sourcesResult, setSourcesResult] = useState([]);
	const [phrases, setPhrases] = useState([])

	useEffect(() => {
		if(search.get('concept') && Array.isArray(savedConcepts)){
			const concept = savedConcepts.find(c => c.concept_id === search.get('concept'))
			if(concept)
				setConceptSelect([{id: concept.concept_id, name: concept.name}])
		}
	}, [savedConcepts])

	const removeConcept = (index) => {
		setConceptSelect(conceptSelect.filter((itm, _index) => index !== _index))
	}

	const changeLogic = (index) => {
		setPhrases(phrases.map((ph, i) => {
			if(i === index){
				if(ph.text === 'AND')
					ph.text = 'OR'
				else if(ph.text === 'OR')
					ph.text = 'NOT'
				else if(ph.text === 'NOT')
					ph.text = 'AND'
			}
			return ph
		}))
	}

	const changeConceptLogic = () => {
		if(conceptsLogic.text === 'AND')
			conceptsLogic.text = 'OR'
		else if(conceptsLogic.text === 'OR')
			conceptsLogic.text = 'NOT'
		else if(conceptsLogic.text === 'NOT')
			conceptsLogic.text = 'AND'

		setConceptLogic({...conceptsLogic})
	}

	const removePhrase = (index) => {
		if(index > 0){
			setPhrases(phrases.filter((ph, i) => (index !== i && index-1 !== i)))
		}else if(index === 0 && phrases.length > 1){
			setPhrases(phrases.filter((ph, i) => (index !== i && index+1 !== i)))
		}else if(index === 0 && phrases.length === 1)
			setPhrases([])
	}

	return (
		<main className={'main-content'}>
			<div className="content visible">

				<QueryStringConstructor phrases={phrases} setPhrases={setPhrases} searchSelect={conceptSelect} setSearchSelect={setConceptSelect} />

				{(phrases.length > 0 || conceptSelect.length > 0) &&
					<>
						<div className='title-mid c-main-dark'>Ваш поисковый запрос:</div>
						<div className='search-phrases'>
							{phrases.map((phrase, index) => {
								if (phrase.type === 'bool'){
									return <div key={`${phrase.text}_${index}`} onClick={e => changeLogic(index)} className={`search-phrases__item search-phrases__item--bool ${phrase.text}`}>{phrase.text}</div>
								}
								return <div key={`${phrase.text}_${index}`} className='search-phrases__item title search-phrases__item--text'>{phrase.text}<div onClick={e => {removePhrase(index)}} className='cross' /></div>
							})}

							{phrases.length > 0 && conceptSelect.length > 0 &&
								<div key={`concept_logic_${conceptsLogic.text}`} onClick={e => changeConceptLogic()} className={`search-phrases__item search-phrases__item--bool ${conceptsLogic.text}`}>{conceptsLogic.text}</div>
							}

							{conceptSelect.map((concept, index) => {
								return <div key={concept.id} className='search-phrases__item title search-phrases__item--concept'>{concept.text}<div onClick={e => {removeConcept(index)}} className='cross' /></div>
							})}
						</div>
					</>
				}

				<hr/>

				<SourceListSelect
					countriesSelected={countriesSelected}
					setCountriesSelected={setCountriesSelected}
					sourcesResult={sourcesResult}
					setSourcesResult={setSourcesResult}
				/>

				<ShowBtn sources={sourcesResult} phrases={phrases} concepts={conceptSelect} conceptLogic={conceptsLogic} />

				<ListBlock title="Ваши ленты" link="/stream/all" link_name="Посмотреть все ленты" type="streams" limit={5} />
			</div>
		</main>
	);
}

export default PageStream;