import {createSlice} from "@reduxjs/toolkit";

export const streamsSlice = createSlice({
	name: 'streamSlice',
	initialState: {
		list: false
	},
	reducers: {
		setStreamList: (state, action) => {
			state.list = action.payload
		}
	}
})

export const { setStreamList } = streamsSlice.actions

export default streamsSlice.reducer