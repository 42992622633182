import React from "react"
import {HandySvg} from "handy-svg"
import {Link} from "react-router-dom"

import file_doc from "../../../ico/file_doc.svg"
import file_pdf from "../../../ico/file_pdf.svg"
import {BACK_HOST} from "../../../Consts";

function ListBlockItemFile({title, link, type, path, size, date}) {

	function getIcoByFile(file) {
		switch (file) {
			case 'doc':
				return (<HandySvg src={file_doc} width={20} height={18}/>)
			default:
				return (<HandySvg src={file_pdf} width={20} height={18}/>)
		}
	}

	const dateString = new Date(date).toLocaleDateString('ru') + "\n" + new Date(date).toLocaleTimeString('ru')
	return <div className="list-block-itm">
		<div className="list-block-itm__info">
			{link
				? <Link to={link} className="list-block-itm__info__title" >{title}</Link>
				: <div className="list-block-itm__info__title">{title}</div>
			}

			<div  className="list-block-itm__info__file">
				<a href={`${BACK_HOST}/${path}`} download={true} className="list-block-itm-file-link">
					{getIcoByFile(type)}
					<span>{type}</span>
				</a>
				<span className="sep"></span>
				<span>{size}</span>
			</div>
		</div>
		<div className="list-block-itm__date">{dateString}</div>
	</div>
}

export default ListBlockItemFile