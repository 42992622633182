import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {HandySvg} from "handy-svg";
import ListBlockItemFile from "./ListBlockItemFile";
import ListBlockItemJournal from "./ListBlockItemJournal";

import svg_arrow from '../../../ico/arrow.svg';
import {analyticsBACK} from "../../../ApiEndpoints";
import {useSelector} from "react-redux";
import {isArray} from "@apollo/client/cache/inmemory/helpers";
import Skeleton from "react-loading-skeleton";
import {userSlice} from "../../../reducers/userSlice";

function ListBlock({type, limit = 5, filesType, title, link, link_name}) {
	const [data, setData] = useState(false);
	const user = useSelector(state => state.user.value)
	const streams = useSelector(state => state.stream.list)
	const journal = useSelector(state => state.user.history)

	useEffect(() => {
		switch(type) {
			case 'files':{
				let params = {userID: user.id}
				if(filesType)
					params.type = filesType

				analyticsBACK.get('/reports', {
					params: params
				})
					.then(resp => {
						if(isArray(resp.data))
							setData(resp.data)
						else
							setData([])
					})
				break
			}
			case 'streams': {
				setData(streams)
				break
			}
			case 'journal': {
				setData(journal)
				break
			}
			default:
				setData({})
		}

	},[streams, journal])

	function renderSwitch(type) {
		let result = ''
		switch(type) {
			case 'files':{
				return  data.map((itm, index) =>{
					if(limit && index > limit - 1)
						return false

					let type = ''
					switch (itm.type){
						case "dossier":{
							type = 'Досье'
							break;
						}
						case "stream":{
							type = 'Лента'
							break;
						}
					}
					return (<ListBlockItemFile key={itm.id} link={itm.link} title={`${type} ${itm.concept_name}`} path={`public/${itm.file_path}`} size={itm.file_size} date={itm.created_at} type='doc'/>)
				})
			}
			case 'streams':{
				return data.map((item, index) => {
					if(index > limit -1)
						return ''
					return <ListBlockItemJournal key={item.id} link={`/stream/content${item.query}&from_saved=${item.id}`} update={item.updated} title={item.name} date={item.created_at} />
				})
			}
			case 'journal': {
				return data.map( (item, index)=> {
					if(index > limit -1)
						return ''
					return <ListBlockItemJournal key={'journal_'+item.id} link={item.link} title={item.title} date={item.created_at}/>
				} )
			}
			default:{
				result = Object.keys(data).map((key, index) =>{
					if(index > limit -1)
						return ''
					let itm = data[key]
					return (<ListBlockItemJournal key={key}  title={itm.name} date={itm.date}/>)
				})
				return result
			}
		}
	}

	return <div className="list-block">
		<div className="title c-main-dark">{title}</div>
		<div className="list-block-content">
			{isArray(data) && data.length > 0
				? renderSwitch(type)
				: data === false
					? <Skeleton height="30px" count={3} />
					: <p>Здесь еще ничего нет</p>
			}
		</div>
		{link &&
			<Link to={link} className="list-block__link">
				<span>{link_name}</span>
				<HandySvg src={svg_arrow} width={20} height={18}/>
			</Link>
		}
	</div>

}

export default ListBlock;