import React, {useState} from "react";
import {analyticsBACK} from "../../ApiEndpoints";
import {setSavedConcepts} from "../../reducers/userSlice";
import {useDispatch, useSelector} from "react-redux";

const RememberListItem = ({clickResultOption, concept}) => {
	const user = useSelector(state => state.user.value)
	const [rememberedRemoving, setRememberedRemoving] = useState('')
	const dispatch = useDispatch()

	const rememberRemove = (id) => {
		setRememberedRemoving(id)
		analyticsBACK.delete('/concept/saved', {
			data: {
				userID: user.id,
				conceptID: id
			}
		})
			.then(resp => {
				dispatch(setSavedConcepts(resp.data))
				setRememberedRemoving('')
			})
			.catch(resp => {
				console.error(resp)
				setRememberedRemoving('')
			})
	}

	return <li key={`remembered_${concept.concept_id}`} style={{opacity: ((rememberedRemoving === concept.concept_id)? '0.4' : '1')}}>
		<div className="remember-list__itm" onClick={(e) => {
			clickResultOption({...concept})
		}}>
			<span className="remember-list__itm__text">
				{concept.name}
			</span>
		</div>
		<div className="remember-list__remove js-remember-remove" onClick={(e) => {
			rememberRemove(concept.concept_id)
		}}>
			<div className="cross"></div>
		</div>
	</li>
}

export default RememberListItem