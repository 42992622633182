import React, {useEffect} from "react"
import ListBlock from "../../components/ui/listBlock/ListBlock";
import useQuery from "../../functions/useQuery";

const SmartSearch = React.lazy(() => import("../../components/search/SmartSearch"))

const Query = () => {
	let query = useQuery();
	useEffect(() => {
		document.title = 'Мгимо аналитика: создание досье'
	}, [])

	return <main className='main-content content visible'>
		<div className="title c-main-dark">Выберите объект для формирования досье:</div>

		<SmartSearch activeRememberedName={query.get('name')} activeRemembered={query.get('concept')} />

		<ListBlock
			title="Ранее сформированные досье"
			link="/reports"
			link_name="Посмотреть все отчеты"
			type="files"
			filesType='dossier'
			limit={5}
		/>
	</main>
}

export default Query