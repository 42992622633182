import React from "react";

const RememberList = ({children, title = "Запомненные объекты:"}) => {
	return (
		<div className="remember-list">
			<div className="title c-main-dark">{title}</div>
			<ul>
				{children}
			</ul>
		</div>
	)
}

export default RememberList