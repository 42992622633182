export const STEAM_CHART_BAR = {
	chart: {
		id: "basic-bar",
		toolbar: {
			show: false,
		},
		fontFamily: "Intro",
	},
	yaxis: {
		show: false
	},
	tooltip: {
		enabled: false,
	}

};