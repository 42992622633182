import React, {useEffect, useRef, useState} from "react";
import ico_search from "../../ico/search.svg"
import {HandySvg} from "handy-svg";
import {APIClient} from "../../ApiEndpoints";
import {SMART_SEARCH} from "../../graphql/statisticQueries";
import RememberListItem from "../search/RememberListItem";
import RememberList from "../search/PememberList";
import {useSelector} from "react-redux";
import {isArray} from "@apollo/client/cache/inmemory/helpers";
import Skeleton from "react-loading-skeleton";

function SmartSearchMulti({select, setSelect, fullText = false, textPhrases, setTextPhrases}) {
	const [list, setList] = useState([])
	const savedConcepts = useSelector(state => state.user.savedConcepts)

	const input = useRef()
	const block = useRef()

	let inputTimeout

	useEffect(() => {
		// Клик вне
		document.addEventListener("click", (e) => {
			if (document.querySelector('.smart-search-input') && !e.target.closest('.smart-search-input')) {
				document.querySelector('.smart-search-block').classList.remove('smart-search-block--list');
			}
		});
	}, [])

	function searchInput(e) {
		let val = e.currentTarget.value;

		block.current.classList.remove('smart-search-block--list');
		clearTimeout(inputTimeout);

		if (val.length === 0) {
			return false;
		}

		inputTimeout = setTimeout(function () {
			search(val)
		}, 600);
	}

	function search(val) {
		block.current.classList.add('smart-search-block--load')

		APIClient.query({
			query: SMART_SEARCH,
			variables: {
				query: val
			}
		})
			.then((resp) => {
				if(resp.data && resp.data.paginationConcept)
					setList(resp.data.paginationConcept.listConcept)
				block.current.classList.remove('smart-search-block--load')
				block.current.classList.add('smart-search-block--list')
			})
			.catch((resp) => {
				console.error("SMART_SEARCH_FAIL", resp)
			})
	}

	function clickResultOption(concept) {
		if(select.find(res => res.id === concept.id))
			setSelect(select.filter(res => res.id !== concept.id))
		else
			setSelect([...select, concept])

		block.current.classList.remove('smart-search-block--list')
	}

	function resultRemove(concept) {
		if(select.find(res => res.id === concept.id))
			setSelect(select.filter(res => res.id !== concept.id))
	}

	function markupResult() {

		if (select.length === 0) {
			return (<></>)
		}

		return select.map(concept => {
			return (
				<div key={`selectedConcept_${concept.id}`} className="smart-search-multiple-result-itm">
					<div className="title c-main-dark">{concept.name}</div>
					<div className="multiple-result-remove js-remove-multiple-result"
						 onClick={(e) => {
							 resultRemove(concept)
						 }}>
						<div className="cross"></div>
					</div>
				</div>
			)
		})

	}

	const handleFullTextPhrase = (e) => {
		e.preventDefault()
		if(textPhrases.length === 0){
			setTextPhrases([input.current.value])
		}else{
			setTextPhrases([...textPhrases, 'AND', input.current.value])
		}
		input.current.value = ''
		clearTimeout(inputTimeout);
	}

	const changeLogic = (index) => {
		const tmp = textPhrases
		if(textPhrases[index] === "AND"){
			tmp[index] = "OR"
		}else{
			tmp[index] = "AND"
		}
		setTextPhrases([...tmp])
		input.current.value = ''
		clearTimeout(inputTimeout);
	}

	const printSearchPhrase = () =>{
		return textPhrases.map((phrase, index) => {
			if(phrase === 'AND' || phrase === 'OR'){
				return <span key={`phrases_${index}`} onClick={(e) => {changeLogic(index)}} className='search-phrase__phrase search-phrase__phrase--logic'> {phrase} </span>
			}else
				return <span key={`phrases_${index}`} className='search-phrase__phrase'> {phrase} </span>
		})
	}

	return (
		<div ref={block} className="nice-form smart-search-block">
			<div className='flex source-block-search-top' style={{width: '100%'}}>
				<div className="col">
					<div className="smart-search-input">
						<label className="input-wrap with-ico">
							<HandySvg src={ico_search} width={20} height={18} className="input-wrap__ico"/>

							<input ref={input} type="text" placeholder=" "
								   onInput={searchInput}
								   onFocus={(e) => {
									   let block = e.currentTarget.closest('.smart-search-block');
									   block.classList.add('smart-search-block--list')
								   }
							}/>
							<div className="input-wrap__label">Введите поисковый запрос</div>
						</label>

						<div className="smart-search-list">
							{list.length > 0 &&
								list.map(source => {
									return <div key={source.id} className="smart-search-list-itm" onClick={(e) => {
										clickResultOption(source)
									}}>{source.name}</div>
								})
							}
						</div>
					</div>
				</div>

				<div className="col">
					{fullText &&
						<button className='btn small' name="fullTextPhrase" onClick={handleFullTextPhrase}>Добавить фразу</button>
					}
				</div>
			</div>

			{fullText && textPhrases.length > 0 &&
				<div style={{margin:"20px 0"}}>
					<p className="title c-main-dark">Поисковая фраза:</p>
					<div className="search-phrase">
						{printSearchPhrase()}
					</div>
				</div>
			}

			<div className="smart-search-result smart-search-result--multi">
				{markupResult()}
			</div>

			<RememberList>
				{isArray(savedConcepts)
					?savedConcepts.map(concept => {
						return <RememberListItem key={`rl_${concept.id}`} clickResultOption={clickResultOption} concept={concept}></RememberListItem>
					})
					: <><Skeleton width="100px" height='43px' /><Skeleton width="150px" height='43px' /></>
				}
			</RememberList>

		</div>
	)
}

export default SmartSearchMulti;