import {createSlice} from "@reduxjs/toolkit";

export const userSlice = createSlice({
	name: 'userState',
	initialState: {
		value: null,
		auth: false,
		savedConcepts: 'fetching',
		history: false
	},
	reducers: {
		setUserData: (state, action) => {
			if(action.payload) {
				if (/^analytics_/.test(action.payload.login))
					action.payload.local = true
				else
					action.payload.local = false
			}
			state.value = action.payload
		},
		setAuthData: (state, action) => {
			state.auth = action.payload
		},
		setSavedConcepts: (state, action) => {
			state.savedConcepts = action.payload
		},
		setHistory: (state, action) => {
			state.history = action.payload
		}
	}
})

export const { setUserData, setAuthData, setSavedConcepts, setHistory} = userSlice.actions

export default userSlice.reducer