import React from "react"
import {Link} from "react-router-dom"

function ListBlockItemJournal({title, date, link, update}) {

	const dateString = new Date(date).toLocaleDateString('ru') + "\n" + new Date(date).toLocaleTimeString('ru')
	return <Link to={link} className="list-block-itm">
		<div className="list-block-itm__info">
			<div className="list-block-itm__info__title">
				{title}
				{update
					?<span className='badge info'>новое</span>
					:''
				}
			</div>
		</div>
		<div className="list-block-itm__date">{dateString}</div>
	</Link>
}

export default ListBlockItemJournal