import {createSlice} from "@reduxjs/toolkit";

export const conceptSlice = createSlice({
	name: 'conceptSlice',
	initialState: {
		shortList: [],
		connectionsType: {}
	},
	reducers: {
		setConceptsShortList: (state, action) => {
			state.shortList = action.payload
		},
		setConnectionsTypes: (state, action) => {
			state.connectionsType = action.payload
		}
	}
})

export const { setConceptsShortList, setConnectionsTypes } = conceptSlice.actions

export default conceptSlice.reducer