import React from 'react';
import ListBlock from "../../components/ui/listBlock/ListBlock";

const PageReports = () => {
	return (
		<main className={'main-content'}>
			<div className="content visible all-reports-page">
				<h1 className="title-big main-margin c-main-dark">Сформированные отчеты</h1>

				<ListBlock  type="files"></ListBlock>

			</div>
		</main>
	);
}

export default PageReports;